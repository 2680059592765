import { useEffect } from "react";

export const useClickOutside = (node, callback, dependencies) => {
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (node && node.current && node.current.contains(e.target)) {
        return;
      }
      callback(false);
    };
    if (dependencies) {
      document.addEventListener("mouseup", handleClickOutside);
    } else {
      document.removeEventListener("mouseup", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [node, dependencies, callback]);
};
