import _ from "lodash";
import { MinusIcon, PlusIcon, Typography, Wrapper } from "preventx-storybook";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { TreeItems } from ".";
import standardize from "../../../json/standardize";
import { state, traverse } from "./treeHelper";

const Ul = styled.ul``;
const Li = styled.li``;

export const Tree = ({ data, handler, collapsedList, toggleCollapse }) => {
  const compute = (checkboxId, status, node, children) => {
    const nodeCpy = _.cloneDeep(node);
    const updatedList = traverse([...nodeCpy], checkboxId, status);
    handler(checkboxId, status, children, updatedList);
  };

  const treeItems = (status, code, expand, count, c) => (
    <TreeItems
      state={state}
      status={status}
      parent={code}
      expand={expand}
      count={count}
      compute={(parent, value) => compute(parent, value, data, c)}
    />
  );
  const renderTreeItems = (items, isRoot = true) =>
    _.map(items, ({ code, children, count, status, expand }, index) => (
      <Wrapper className={isRoot && "root-node"} key={`tree-${index + 1}`}>
        <Li>
          {Array.isArray(children) && children.length > 0
            ? collapsable(isRoot, status, code, expand, count, children)
            : treeItems(status, code, expand, count, children)}
        </Li>
      </Wrapper>
    ));

  function collapsable(isRoot, status, code, expand, count, children) {
    return (
      <>
        <Wrapper
          className="accordion"
          flex
          align="center"
          justify="space-between">
          {isRoot ? (
            <Typography variant="label" style={{ fontSize: "14px" }}>
              {standardize[code] || code}
            </Typography>
          ) : (
            treeItems(status, code, expand, count, children)
          )}
          <Wrapper handler={() => toggleCollapse(code)}>
            {collapsedList[code] ? <MinusIcon /> : <PlusIcon />}
          </Wrapper>
        </Wrapper>

        <Wrapper
          className={
            collapsedList[code]
              ? "accordion__content-active"
              : "accordion__content"
          }>
          <Ul>{Array.isArray(children) && renderTreeItems(children, false)}</Ul>
        </Wrapper>
      </>
    );
  }

  return (
    <Wrapper className="tree-1" testId="sb-tree">
      {renderTreeItems(data)}
    </Wrapper>
  );
};

Tree.defaultProps = {
  handler: () => null,
  toggleCollapse: () => null,
  data: [],
  collapsedList: {}
};

Tree.propTypes = {
  data: PropTypes.instanceOf(Array),
  handler: PropTypes.func,
  collapsedList: PropTypes.instanceOf(Array),
  toggleCollapse: PropTypes.func
};
