import { DATE_FORMAT } from "../constants/defaults";
import { ValidateDate } from "../utils/utilsFunction";

const SearchCaseForm = [
  {
    name: "FirstName",
    placeholder: "enter-firstname",
    label: "firstname"
  },
  {
    name: "LastName",
    placeholder: "enter-surname",
    label: "surname"
  },
  {
    name: "DateOfBirth",
    placeholder: DATE_FORMAT,
    label: "doB",
    format: DATE_FORMAT,
    seperator: "/",
    type: "date",
    rules: {
      validate: {
        checkDate: (v) => ValidateDate(v, DATE_FORMAT) || "Invalid-Date"
      }
    }
  },
  {
    name: "ContactNumber",
    placeholder: "1234567890",
    label: "mobile-number",
    type: "phone",
    rules: {
      validate: {
        checkNumber: (v) => {
          if (v?.number && v?.number?.length < 4) {
            return "phone-validation";
          }
          return true;
        }
      }
    }
  },
  {
    name: "EmailAddress",
    placeholder: "Placeholder-Email",
    label: "email-address",
    rules: {
      pattern: {
        value:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: "Email-Validate-Message"
      }
    }
  },
  {
    name: "PostCode",
    placeholder: "SW1W 0NY",
    label: "post-code"
  }
];

export default SearchCaseForm;
