module.exports = {
  DEFAULT_PAGE_SIZE: 20,
  DATE_FORMAT: "DD/MM/YYYY",
  validState: { error: false, helperText: "" },
  apiErrorObj: {
    isError: false,
    message: null,
    code: null
  },
  PARTNER_TRACING_URL: "https://sh.uk",
  NAV_HEIGHT: 56,
  FILTER_HEADER_HEIGHT: 66,
  FILTER_FOOTER_HEIGHT: 80,
  CLINICAL_USER_URL: "account/users",
  MY_PROFILE_URL: "account/profile",
  REPORTING_URL: "account/menu/reporting",
  SMART_KITS_URL: "account/menu/smartkits",
  FREE_TEST_ME_SMART_KITS_URL: "account/menu/freetest",
  REFERRER_URL: "account/shuk/view/referrers",
  MANAGE_NOTIFICATON_URL: "account/shuk/manage/settings/notificationwindows",
  ACTION_ATTEMPTED: "ActivityType-TaskAttempted",
  PROCESS_PRIORITY: ["safeguarding", "testing", "partnertracing", "care"],
  STATUS_PROPRITY: ["Overdue", "Due", "Upcoming", "Closed"],
  PROCESS_DEFAULT_OPEN: ["Overdue", "Due"],
  ProcessTypePartnerTracing: "ProcessType-PartnerTracing",
  ProcessTypeSafeguarding:"ProcessType-Safeguarding",
  ProcessTypeCare:"ProcessType-Care",
  ProcessTypeTesting:"ProcessType-Testing",
  HEPC : "National HepC Portal",
  MOBILE_NUMBER_FIELD: 'MobileNumber',
  EMAIL_ADDRESS_FIELD: 'EmailAddress',
  ERROR_STATUS_400: 400,
  ORDERS_API_TYPE: "orders"
};
