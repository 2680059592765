import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate
} from "@azure/msal-react";
import { Loader, Page, Spinner, Wrapper } from "preventx-storybook";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { FilterButton } from "../../components/molecules";
import { Filters } from "../../components/organisms";
import { Table, TableHead } from "../../components/organisms/TodayList";
import { resetFilters } from "../../store/filterlist/actionCreator";
import {
  getTodayListMore,
  getTodayList,
  resetTodayList
} from "../../store/todaylist/logic";
import { scrollContent } from "../../utils/utilsFunction";
import { AuthenticateTemplate } from "../AuthenticateTemplate";

export const TodaysList = ({ filterType }) => {
  const dispatch = useDispatch();
  const todayListData = useSelector((state) => state.todayListReducer);
  const searchCountState = useSelector((state) => state.searchCountReducer);

  const { filters, error: filterError } = useSelector(
    (state) => state.filterListReducer.workList
  );
  const { error } = todayListData;

  useEffect(() => {
    if (filters?.length > 0) {
      dispatch(getTodayList({ filterType }));
    }
    scrollContent(todayListData, "tablelist-scroll");
    return () => {
      dispatch(resetTodayList());
    };
  }, [filters?.length]);

  const fetchMoreData = () => {
    dispatch(getTodayListMore({ filterType }));
  };

  useEffect(
    () => () => {
      if (filters?.length > 0 && !searchCountState.isSearch) {
        dispatch(resetFilters(filterType, false));
      }
    },
    [filterType, filters?.length, searchCountState.isSearch]
  );

  return (
    <>
      <AuthenticatedTemplate>
        <Page
          title="Today"
          noScroll
          miniActions={<FilterButton />}
          sideBar={<Filters filterType={filterType} />}>
          <TableHead />

          <Loader
            loading={filterError.isError ? false : todayListData.loading}
            error={error.isError}
            errorMessage={filterError.message || error.message}
            errorCode={error.code}
            size={48}
            className="todaylist-loader"
            height="70vh"
            noData={!todayListData?.data?.pagedResults?.length}>
            <InfiniteScroll
              tabindex={0}
              className="tablelist-scroll smothscroll scrollbar"
              dataLength={
                !todayListData || todayListData?.data?.pagedResults?.length || 0
              }
              next={fetchMoreData}
              hasMore={
                todayListData?.data?.pagedResults?.length <
                todayListData?.data?.totalCount
              }
              scrollThreshold={0.9}
              loader={<Spinner size={48} />}
              height="calc(100vh - 160px)"
              width="100%">
              <Wrapper tabIndex={0}>
                <Table
                  totalCount={todayListData?.data?.totalCount}
                  totalDue={todayListData?.data?.totalDue}
                  totalOverdue={
                    todayListData?.data?.totalOverdue === 0
                      ? 0
                      : todayListData?.data?.totalOverdue
                  }
                  data={todayListData?.data?.pagedResults}
                  title="CaseStatusOverDue"
                />
              </Wrapper>
            </InfiniteScroll>
          </Loader>
        </Page>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <AuthenticateTemplate />
      </UnauthenticatedTemplate>
    </>
  );
};

TodaysList.defaultProps = {
  filterType: "workList"
};

TodaysList.propTypes = {
  filterType: PropTypes.string
};
