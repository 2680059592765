import React, { useState } from "react";
import { TextField } from "preventx-storybook";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const CaseSearch = ({ onChange, isDisabled }) => {
  const searchCaseState = useSelector((state) => state.searchCaseReducer);
  const [search, setSearch] = useState(
    searchCaseState?.data?.CaseReference || ""
  );

  return (
    <TextField
      placeholder="CS123456"
      value={search}
      handler={(e) => {
        setSearch(e.target.value);
        onChange(e.target.value);
      }}
      isDisabled={isDisabled}
      className="txt-cap"
    />
  );
};

CaseSearch.defaultProps = {
  onChange: () => null,
  isDisabled: false
};

CaseSearch.propTypes = {
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool
};

export default CaseSearch;
