import { Button, FilterIcon, Wrapper } from "preventx-storybook";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleBackdrop } from "../../../store/backdrop/actionCreator";
import { setToggleFilterDrawer } from "../../../store/filterlist/actionCreator";

export const FilterButton = ({ handler }) => {
  const { isFilterOpen } = useSelector((state) => state.filterListReducer);
  const { showBackdrop } = useSelector((state) => state.backDropReducer);
  const dispatch = useDispatch();
  const toggleFilter = () => {
    dispatch(toggleBackdrop(!showBackdrop));
    dispatch(setToggleFilterDrawer(!isFilterOpen));
  };

  return (
    <Wrapper className="caseList-filterButton">
      <Button
        variant="secondary"
        icon={<FilterIcon />}
        handler={() => (handler ? handler() : toggleFilter())}>
        filter
      </Button>
    </Wrapper>
  );
};

FilterButton.defaultProps = {
  handler: null
};
FilterButton.propTypes = {
  handler: PropTypes.func
};
