import _ from "lodash";

export const state = Object.freeze({
  unchecked: 0,
  checked: 1,
  indeterminate: -1
});

const setStatus = (root, status) => {
  const node = root;
  node.status = status;
  if (Array.isArray(node.children)) {
    return _.forEach(node.children, (item) => {
      setStatus(item, status);
    });
  }
  return node;
};

const computeStatus = (child) => {
  let checked = 0;
  let indeterminate = 0;
  child.forEach((item) => {
    if (item.status && item.status === state.checked) checked += 1;
    if (item.status && item.status === state.indeterminate) indeterminate += 1;
  });

  if (checked === child.length) {
    return state.checked;
  }
  if (checked > 0 || indeterminate > 0) {
    return state.indeterminate;
  }
  return checked;
};

export const traverse = (root, checkedId, status) => {
  let code;
  let children;
  const node = root;
  if (Array.isArray(node)) {
    children = node;
  } else {
    [code, children] = [node.code, node.children];
  }
  if (_.isArray(checkedId) ? _.includes(checkedId, code) : code === checkedId) {
    return setStatus(node, status);
  }
  if (children.length < 1 || !children) {
    return node;
  }
  children.forEach((item) => traverse(item, checkedId, status));
  node.status = computeStatus(children);
  return node;
};

/** Function to retrieve the codes of the deeply nested children of a parent node */
export const getNestedFilters = (selectedFilters) => {
  const params = [];
  if (_.isArray(selectedFilters)) {
    _.forEach(selectedFilters, (item) => {
      if (item.children.length < 1) params.push(item.code);
      else params.push(...getNestedFilters(item.children));
    });
  }
  return params;
};

export const getFilterCodes = (
  code,
  isChecked,
  parentNode = [],
  currentFilters
) => {
  let params = [];
  /** If a parent node is checked/unchecked then retrieve its childrens code
   * Childrens code will be sent as filter params
   */
  if (parentNode.length) {
    params = isChecked
      ? _.uniq([...currentFilters, ...getNestedFilters(parentNode)])
      : _.difference(currentFilters, getNestedFilters(parentNode));
  } else {
    params = isChecked
      ? _.uniq([...currentFilters, code])
      : _.difference(currentFilters, [code]);
  }
  return params;
};

export const getDefaultCheckedFilters = (node) => {
  const { children } = _.find(node, { code: "CaseStatus" });
  const filters = _.find(children, { code: "Open" });
  return filters.children || [];
};

export const resetTree = (filters) => {
  const initialFilter = _.cloneDeep(filters);
  const updatedFilters = _.forEach(initialFilter, (item) => {
    traverse([...initialFilter], item.code, state.unchecked);
  });
  return updatedFilters;
};
