import _ from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { DATE_FORMAT } from "../../../constants/defaults";
import SearchCaseForm from "../../../json/searchCaseForm";
import {
  clearSearchCountNumbers,
  getsearchCount,
  resetsearchCount
} from "../../../store/searchcount/logic";
import {
  convertToDateOnlyFormat,
  dayconvertToFormat
} from "../../../utils/utilsFunction";
import { FormBuilder } from "../../molecules";

const UserDetail = ({ isDisabled }) => {
  const searchCountState = useSelector((state) => state.searchCountReducer);
  const dispatch = useDispatch();
  const {
    formState: { isValid, errors },
    control
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange"
  });

  useEffect(() => {
    if (
      searchCountState.data &&
      searchCountState.data.filters &&
      !searchCountState.data.caseCount
    ) {
      dispatch(getsearchCount({ ...searchCountState.data.filters }));
    }
  }, []);

  const [inputData, setInputData] = useState(
    (searchCountState?.data?.filters && {
      ...searchCountState?.data?.filters,
      DateOfBirth:
        searchCountState?.data?.filters?.DateOfBirth &&
        dayconvertToFormat(
          searchCountState?.data?.filters?.DateOfBirth,
          DATE_FORMAT
        )
    }) ||
      {}
  );

  const getCount = () => {
    if (isValid) {
      const params = { ...inputData };

      if (params?.ContactNumber) {
        const { value, number } = params.ContactNumber;
        if (!value || !number) {
          delete params.ContactNumber;
        }
      }
      if (
        JSON.stringify(searchCountState?.data?.filters) !==
        JSON.stringify(inputData)
      ) {
        if (params?.DateOfBirth) {
          params.DateOfBirth = convertToDateOnlyFormat(
            params.DateOfBirth,
            DATE_FORMAT,
            "YYYY-MM-DD"
          );
        }

        Object.keys(params).forEach((item) => {
          if (!params[item]) {
            delete params[item];
          }
        });
        if (_.keys(params).length > 0) {
          dispatch(getsearchCount({ ...params }));
        } else {
          dispatch(clearSearchCountNumbers());
        }
      }
    }
  };
  const debounceFn = useCallback(_.debounce(getCount, 1000), [
    isValid,
    inputData
  ]);

  useEffect(() => {
    debounceFn();

    return debounceFn.cancel;
  }, [isValid, debounceFn]);

  const onFormChange = (obj) => {
    let flag = -1;
    const tmpData = { ...inputData, ...obj };
    Object.keys(tmpData).forEach((key) => {
      if (tmpData[key] !== "") {
        flag += 1;
      }
    });
    setInputData(obj);

    if (flag === -1) {
      dispatch(resetsearchCount());
    }
  };

  return (
    <form>
      <FormBuilder
        formjson={SearchCaseForm}
        inputModel={inputData}
        errors={errors}
        control={control}
        onChangeHandle={onFormChange}
        isDisabled={isDisabled}
      />
    </form>
  );
};

UserDetail.defaultProps = {
  isDisabled: false
};

UserDetail.propTypes = {
  isDisabled: PropTypes.bool
};

export default UserDetail;
