import _ from "lodash";
import { stringify } from "query-string";
import { all, put, select, takeLatest } from "redux-saga/effects";
import { DEFAULT_PAGE_SIZE } from "../../constants/defaults";
import { apiCall, apiException, apiSuccess } from "../../services/api";
import { getState, updateNoteOutcomesKey } from "../../utils/helper";
import { dropdownConverter } from "../../utils/utilsFunction";
import { getCareProcess } from "../care/logic";
import { getPartnerTracingProcess } from "../partnertracing/logic";
import { getSafeguardingProcess } from "../safeguarding/logic";
import { getTestingProcess } from "../testing/logic";
import {
  addNotes,
  clearNotesError,
  deleteOutcomesSpinner,
  getNotesList,
  insertNewNote,
  resetNotesForm,
  saveNoteSpinner,
  setEditNote,
  setFilterOutcomeTypes,
  setFlaggedUserNotes,
  setFlagSpinner,
  setNoteCategories,
  setNoteListFilter,
  setNotesEditHistory,
  setNotesError,
  setNotesList,
  setNotesListLoading,
  setNoteTasks,
  setNoteTypes,
  setOutcomeTypes,
  setProcesses,
  setRecentNoteId,
  updateFlaggedNotes
} from "./actionCreator";
import {
  ADD_NOTE_LIST_FILTER,
  DELETE_OUTCOMES,
  FILTER_OUTCOME_TYPES,
  GET_FLAGGED_USER_NOTES,
  GET_NOTES_EDIT_HISTORY,
  GET_NOTES_LIST,
  GET_NOTE_BY_ID,
  GET_NOTE_CATEGORIES,
  GET_NOTE_TASKS,
  GET_NOTE_TYPES,
  GET_OUTCOME_TYPES,
  GET_PROCESSES,
  INSERT_NEW_NOTE,
  SET_NOTE_FLAG
} from "./actionTypes";

function* updatePagedResults(noteId, isFlagged, noteType) {
  const { notesList } = yield select(
    (state) => state.notesReducer.response.notes
  );
  const { pagedResults, totalFlagged = 0 } = _.cloneDeep(notesList);
  const key = noteType === "user" ? "serviceUserNoteId" : "caseNoteReference";
  const itemIndex = _.findIndex(pagedResults, {
    [key]: noteId
  });
  if (itemIndex > -1) {
    pagedResults[itemIndex].isFlagged = isFlagged;
    let flagged = 0;
    if (isFlagged) {
      flagged = totalFlagged + 1;
    } else if (!isFlagged) {
      flagged = totalFlagged > 0 ? totalFlagged - 1 : 0;
    }
    const totalCount = pagedResults.length;
    yield put(updateFlaggedNotes(pagedResults, totalCount, flagged));
  }
}

function* getNotes({
  noteType,
  id,
  loadMore,
  resetPage,
  filters,
  showLoader = true
}) {
  yield put(clearNotesError("notesList"));

  try {
    if (showLoader) {
      yield put(setNotesListLoading(true));
    }

    const { pageNumber, response, pageSize } = yield select(
      (state) => state.notesReducer
    );
    const { noteCategoryOptions, noteTypeOptions, outcomeTypes } = response;

    if (noteCategoryOptions.length < 1) {
      yield getNoteCategories();
    }
    if (noteTypeOptions.length < 1) {
      yield getNoteTypes();
    }
    if (outcomeTypes.length < 1) {
      yield getOutcomeTypes();
    }

    const updatedPageNumber = loadMore
      ? pageNumber + 1
      : resetPage || pageNumber;

    const path =
      noteType === "user"
        ? `serviceusers/${id}/usernotes`
        : `CaseNotes/Case/${id}`;

    yield all([filters && put(setNoteListFilter(filters))]);

    const notesListResponse = yield apiCall({
      method: "get",
      apiType: noteType === "user" ? "serviceusers" : "clinicalcases",
      url: `${path}?${
        filters && filters.length > 0
          ? stringify({
              NoteCategoryIds: filters
            })
          : ""
      }&PageNumber=${updatedPageNumber}&PageSize=${pageSize}`
    });

    yield all([
      put(setNotesList(notesListResponse, updatedPageNumber, loadMore)),
      put(setNotesListLoading(false))
    ]);
  } catch (e) {
    yield all([
      put(
        apiException(
          setNotesError({
            key: "notesList",
            code: e.code || e,
            message: e.message || "NotesList-Fail"
          })
        )
      ),
      put(setNotesListLoading(false)),
      filters && put(setNoteListFilter(filters))
    ]);
  }
}

function* getFlaggedUserNotes({ serviceUserReference }) {
  try {
    const response = yield apiCall({
      method: "get",
      apiType: "serviceusers",
      url: `serviceusers/${serviceUserReference}/usernotes`
    });

    yield all([put(setFlaggedUserNotes(response))]);
  } catch (e) {
    yield all([
      put(
        apiException(
          setNotesError({
            key: "flaggedUserNotes",
            code: e.code || e,
            message: e.message || "FlaggedUserNotes-Fail"
          })
        )
      )
    ]);
  }
}

function* getNoteCategories() {
  yield put(clearNotesError("noteCategories"));
  try {
    const response = yield apiCall({
      url: "CaseNotes/NoteCategories"
    });

    const updatedJson = dropdownConverter(response, "localisationKey", "id");
    yield put(setNoteCategories({ updatedJson, original: response }));
  } catch (e) {
    yield put(
      apiException(
        setNotesError({
          key: "noteCategories",
          code: e.code || e,
          message: e.message || "NoteCategories-Fail"
        })
      )
    );
  }
}

function* getNoteTypes() {
  yield put(clearNotesError("noteTypes"));
  try {
    const response = yield apiCall({
      url: "CaseNotes/NoteTypes"
    });
    const updatedJson = dropdownConverter(response, "localisationKey", "id");
    yield put(setNoteTypes(updatedJson));
  } catch (e) {
    yield put(
      apiException(
        setNotesError({
          key: "noteTypes",
          code: e.code || e,
          message: e.message || "NoteTypes-Fail"
        })
      )
    );
  }
}

function* getOutcomeTypes() {
  yield put(clearNotesError("noteOutcomes"));
  try {
    const response = yield apiCall({
      url: "CaseNotes/OutcomeTypes"
    });
    yield put(setOutcomeTypes(response));
  } catch (e) {
    yield put(
      apiException(
        setNotesError({
          key: "noteOutcomes",
          code: e.code || e,
          message: e.message || "NoteOutcomes-Fail"
        })
      )
    );
  }
}

function* getProcesses({ caseId }) {
  yield put(clearNotesError("noteProcesses"));

  try {
    const response = yield apiCall({
      url: `Cases/${caseId}/Processes`
    });
    yield put(setProcesses(response));
  } catch (e) {
    apiException(
      setNotesError({
        key: "noteProcesses",
        code: e.code || e,
        message: e.message || "Processes-Fail"
      })
    );
  }
}

function* getNoteTasks({ caseId }) {
  yield put(clearNotesError("noteTasks"));
  try {
    const response = yield apiCall({
      url: `Cases/${caseId}/Tasks`
    });
    yield put(setNoteTasks(response));
  } catch (e) {
    apiException(
      setNotesError({
        key: "noteTasks",
        code: e.code || e,
        message: e.message || "NoteTasks-Fail"
      })
    );
  }
}

function* getNoteById({ caseNoteReference = null, isEdit = false }) {
  try {
    if (isEdit) {
      yield put(setEditNote({ triggerEdit: true, isLoading: true }));
      yield put(setRecentNoteId(caseNoteReference));
    }
    const response = yield apiCall({ url: `casenotes/${caseNoteReference}` });

    if (response) {
      /* isEdit is used to open the edit note form directly when 
       the user clicks on the edit outcome from the left side.
      */
      if (isEdit) {
        const updatedContent = {
          caseReference: response?.caseReference,
          noteTypeId: response?.noteTypeId,
          noteCategoryIds: response?.noteCategoryIds,
          caseNoteReference: response?.caseNoteReference,
          content: response?.content,
          outcomes: updateNoteOutcomesKey(response.outcomes)
        };
        yield put(addNotes("edit", updatedContent));
        yield put(setEditNote({ isLoading: false, triggerEdit: true }));
      }
      yield put(getNotesList("case", response?.caseReference));
    }
  } catch (e) {
    yield put(setNotesListLoading(false));
    yield put(setEditNote({ triggerEdit: false, isLoading: false }));

    yield put(
      apiException({
        code: e || e.code,
        message: `${window.translate("Note-Get-Fail")} ${caseNoteReference}`
      })
    );
  }
}

function* deleteOutcomes({ caseNoteReference = null, isEdit = true }) {
  try {
    yield put(deleteOutcomesSpinner(true));
    const response = yield apiCall({ url: `casenotes/${caseNoteReference}` });
    if (response) {
      const updatedContent = {
        caseReference: response?.caseReference,
        noteTypeId: response?.noteTypeId,
        noteCategoryIds: response?.noteCategoryIds,
        caseNoteReference: response?.caseNoteReference,
        content: response?.content,
        outcomes: []
      };
      yield put(
        insertNewNote(
          "case",
          response.caseReference,
          updatedContent,
          null,
          isEdit
        )
      );
    }
  } catch (e) {
    yield all([
      put(
        apiException({
          code: e.code || e,
          message:
            e.message ||
            `${window.translate("Outcome-Delete-Fail")} ${caseNoteReference}`
        })
      ),
      put(deleteOutcomesSpinner(false))
    ]);
  }
}
function* insertNewNotes(action) {
  yield put(saveNoteSpinner(true));
  const { id, noteType, handler, data: form } = action;

  const data = _.clone(form);

  const apiType = noteType === "user" ? "serviceusers" : "clinicalcases";

  try {
    if (action.isEdit) {
      delete data.noteCategoryIds;
      delete data.noteTypeId;

      const path =
        noteType === "user"
          ? `usernotes/${form.serviceUserNoteId}`
          : `CaseNotes/${form.caseNoteReference}/Case/${id}`;

      yield apiCall({
        method: "put",
        url: path,
        apiType,
        data
      });
    } else {
      const path = noteType === "user" ? "usernotes" : `casenotes/case/${id}`;
      yield apiCall({
        method: "post",
        url: path,
        apiType,
        data
      });
    }

    yield all([
      put(
        apiSuccess(
          `${
            action.isEdit
              ? `${window.translate("Note-Update-Success")}`
              : `${window.translate("Note-Created-Success")}`
          }`
        )
      ),
      put(getNotesList(noteType, id, 0, 1)),
      handler && put(resetNotesForm()),
      put(saveNoteSpinner(false)),
      put(deleteOutcomesSpinner(false)),
      put(setRecentNoteId(null))
    ]);

    if (handler) {
      handler();
    }
    if (noteType === "case") {
      yield reloadProcess(form.noteCategoryIds.join(), form.caseReference);
    }
  } catch (e) {
    const statusCode = e.code ?? e;
    let errorMessageKey = "NoteCreate-Fail";

    if (action.isEdit) {
      errorMessageKey =
        statusCode === 403 ? "NoteDelete-Unauthorised" : "NoteUpdate-Fail";
    }

    yield all([
      put(apiException({ code: statusCode, message: errorMessageKey })),
      put(deleteOutcomesSpinner(false)),
      put(saveNoteSpinner(false))
    ]);
  }
}

function* reloadProcess(noteCategoryId, caseReference) {
  const { noteCategoryOptions } = yield getState("notesReducer", "response");
  const { text } = _.find(noteCategoryOptions?.updatedJson, {
    value: +noteCategoryId
  });
  if (text === "Note-Category-Safeguarding") {
    const { processReference } = yield getState("safeGuardingReducer", "data");
    yield put(
      getSafeguardingProcess({
        caseReference,
        processReference
      })
    );
  }
  if (text === "Note-Category-Testing") {
    const { processReference } = yield getState("testingReducer", "data");
    yield put(
      getTestingProcess({
        caseReference,
        processReference
      })
    );
  }
  if (text === "Note-Category-Care") {
    const { processReference } = yield getState("careReducer", "data");
    yield put(
      getCareProcess({
        caseReference,
        processReference
      })
    );
  }
  if (text === "Note-Category-PartnerTracing") {
    const { processReference } = yield getState(
      "partnerTracingReducer",
      "data"
    );
    yield put(
      getPartnerTracingProcess({
        caseReference,
        processReference
      })
    );
  }
}

function* flagNote({ noteType, noteReference, isFlagged, id }) {
  const apiType = noteType === "user" ? "serviceusers" : "clinicalcases";

  try {
    yield put(setFlagSpinner(noteReference, true));
    const url =
      noteType === "user"
        ? `usernotes/${noteReference}/${isFlagged ? `unflag` : `flag`}`
        : `CaseNotes/${noteReference}/Case/${id}/Flag`;

    yield apiCall({
      method: noteType === "user" ? "put" : "post",
      url,
      apiType,
      ...(noteType === "case" && { data: { isFlagged: !isFlagged } })
    });
    yield updatePagedResults(noteReference, !isFlagged, noteType);
    yield all([put(setFlagSpinner(null, false))]);
  } catch (e) {
    yield all([
      put(
        apiException({
          code: e.code || e,
          message: e?.message || "FlagNote-Fail"
        })
      ),
      put(setFlagSpinner(null, false))
    ]);
  }
}

// Below function to be removed later. Function was added when filter was applicable in user
function* addNoteListFilter({ filters, caseId }) {
  const { filterBy, categories } = filters;
  const { pageNumber, pageSize } = yield select((state) => state.notesReducer);
  const { serviceUserReference } = yield select(
    (state) => state.caseHeaderReducer.data
  );
  yield put(setNoteListFilter(filters));
  const path =
    filterBy === "byUser"
      ? `CaseNotes/ServiceUser/${serviceUserReference}`
      : `CaseNotes/Cas/${caseId}`;

  try {
    const response = yield apiCall({
      method: "get",
      url: `${path}?${stringify({
        NoteCategoryIds: categories
      })}&PageNumber=${pageNumber}&PageSize=${pageSize}`
    });
    yield put(setNotesList(response));
  } catch (e) {
    yield put(
      apiException({
        code: e.code || e,
        message: e.message || window.translate("Note-Filter-Error")
      })
    );
  }
}

const getUniqueProcessId = (processFromCat = [], processFromTypes = []) => {
  // Concat processFromCat and processFromTypes
  const temp = _.concat(processFromCat, processFromTypes);
  // Retrieve the processIds from the combined array
  const processTypeIds = _.map(temp, ({ processTypeId }) => processTypeId);
  // Find the common processTypeIds from processTypeIds array
  const commonProcessIds =
    processTypeIds.length > 1
      ? _.filter(
          processTypeIds,
          (v) => _.filter(processTypeIds, (v1) => v1 === v).length > 1
        )
      : processTypeIds;
  /* remove the duplicate values from commonProcessIds. 
   This will give the process mapped with note category and noteTypes */
  const uniqProcessId = _.uniq(commonProcessIds);
  return uniqProcessId;
};

function* filterOutComeTypes({ options }) {
  const {
    key,
    value: id,
    noteCategoryOptions,
    outcomeTypes,
    noteCategoryIds,
    noteTypeId
  } = options;
  let processFromTypes = [];
  let processFromCat = [];

  let noteId = null;
  if (key === "noteTypeId") {
    noteId = id;

    // Filter all the outcome types available for this particular noteTypeID
    processFromTypes = _.filter(outcomeTypes, { noteTypeId: noteId });

    if (noteCategoryIds && noteCategoryIds?.length) {
      processFromCat = _.filter(noteCategoryOptions.original, (item) =>
        _.includes(noteCategoryIds, item.id)
      );
    }
  }
  if (key === "noteCategories") {
    // Find the process mapped for this particular noteCategory
    processFromCat = _.filter(noteCategoryOptions.original, (item) =>
      _.includes(id, item.id)
    );
    if (noteTypeId) {
      processFromTypes = _.filter(outcomeTypes, { noteTypeId });
      noteId = noteTypeId;
    }
  }
  const processIds = getUniqueProcessId(processFromCat, processFromTypes);
  const outcomes = _.filter(
    outcomeTypes,
    (item) =>
      item.noteTypeId === noteId &&
      _.includes(processIds, item.processTypeId) &&
      _.find(processFromCat, { processTypeId: item.processTypeId })
  );

  yield put(setFilterOutcomeTypes(outcomes));
}

function* getEditNoteHistory({ caseNoteReference }) {
  try {
    const notesEditHistory = yield select(
      (state) => state.notesReducer.response.notes.notesEditHistory
    );

    const { pagedResults, totalCount, pageNumber } =
      notesEditHistory[caseNoteReference] || {};
    let updatedPageNumber = pageNumber || 1;
    if (totalCount && pagedResults?.length < totalCount) {
      updatedPageNumber += 1;
    }

    const response = yield apiCall({
      method: "get",
      url: `casenotes/${caseNoteReference}/history?&PageNumber=${updatedPageNumber}&PageSize=${DEFAULT_PAGE_SIZE}`
    });
    if (pagedResults) {
      const updatedNotesHistory = {
        ...response,
        pagedResults: [...pagedResults, ...response.pagedResults],
        pageNumber: updatedPageNumber
      };

      yield put(setNotesEditHistory(updatedNotesHistory, caseNoteReference));
    } else {
      yield put(
        setNotesEditHistory(
          { ...response, pageNumber: updatedPageNumber },
          caseNoteReference
        )
      );
    }
  } catch (error) {
    yield put(apiException(null, window.translate("Note-History-Get-Fail")));
  }
}

export function* notesGETAction() {
  yield takeLatest([GET_NOTE_CATEGORIES], getNoteCategories);
  yield takeLatest([GET_NOTE_TYPES], getNoteTypes);
  yield takeLatest([GET_NOTE_TASKS], getNoteTasks);
  yield takeLatest([GET_PROCESSES], getProcesses);
  yield takeLatest([GET_OUTCOME_TYPES], getOutcomeTypes);
  yield takeLatest([FILTER_OUTCOME_TYPES], filterOutComeTypes);
  yield takeLatest([GET_NOTES_LIST], getNotes);
  yield takeLatest([GET_NOTE_BY_ID], getNoteById);
  yield takeLatest([DELETE_OUTCOMES], deleteOutcomes);

  yield takeLatest([GET_NOTES_EDIT_HISTORY], getEditNoteHistory);

  yield takeLatest([ADD_NOTE_LIST_FILTER], addNoteListFilter);
  yield takeLatest([GET_FLAGGED_USER_NOTES], getFlaggedUserNotes);
}

export function* notesPOSTAction() {
  yield takeLatest(INSERT_NEW_NOTE, insertNewNotes);
}

export function* notesPUTAction() {
  yield takeLatest(SET_NOTE_FLAG, flagNote);
}
