import { ModalHeader, ModalLayout } from "preventx-storybook";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetsearchCase } from "../../../store/searchcase/logic";
import { resetsearchCount } from "../../../store/searchcount/logic";
import { resetsearchUser } from "../../../store/searchuser/logic";
import {resetsearchScreenReference} from "../../../store/searchscreenreference/logic";
import { SearchForm } from "./SearchForm";

export const SearchModal = ({ isOpen, openHandler }) => {
  const searchCountState = useSelector((state) => state.searchCountReducer);

  const dispatch = useDispatch();
  useEffect(
    () => () => {
      dispatch(resetsearchCase());
      dispatch(resetsearchUser());
      dispatch(resetsearchScreenReference())
    },
    []
  );
  const close = () => {
    if (!searchCountState.isSearch) {
      dispatch(resetsearchCount());
    }
    openHandler();
  };

  return isOpen ? (
    <ModalLayout
      showModal={!!isOpen}
      className="search-modal"
      onCloseModal={close}
      isFullScreen>
      <ModalHeader headerTitle="Search" onCloseModal={close} />
      <div className="moda-body">
        <SearchForm onClose={openHandler} />
      </div>
    </ModalLayout>
  ) : null;
};

SearchModal.defaultProps = {
  openHandler: () => null,
  isOpen: false
};

SearchModal.propTypes = {
  isOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  openHandler: PropTypes.func
};
