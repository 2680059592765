import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Radio,
  Wrapper,
  ModalBody,
  SearchIcon,
  Typography,
  ModalFooter,
  CrossIconFilled
} from "preventx-storybook";

// Constants
import { FILTER_CASE_LIST, FILTER_SERVICE_USERS } from "../../../constants/ids";

// Store Management
import {
  getsearchUser,
  resetsearchUser
} from "../../../store/searchuser/logic";
import {
  getsearchCase,
  resetsearchCase
} from "../../../store/searchcase/logic";
import {
  resetsearchCount,
  updateSearchCount,
  updatesearchFilter
} from "../../../store/searchcount/logic";
import {
  noFilters,
  resetFilters
} from "../../../store/filterlist/actionCreator";
import {
  getsearchScreenReference,
  resetsearchScreenReference
} from "../../../store/searchscreenreference/logic";
import { getCaseList } from "../../../store/caselist/logic";

// Components
import CaseSearch from "./SearchCase";
import SearchFooter from "./SearchFooter";
import ServiceUserSearch from "./SearchUser";
import UserDetail from "./SearchUserDetails";
import SearchScreenReference from "./SearchScreenReference";

export const SearchForm = ({ onClose }) => {
  const dispatch = useDispatch();
  const searchCaseState = useSelector((state) => state.searchCaseReducer);
  const searchUserState = useSelector((state) => state.searchUserReducer);
  const searchCountState = useSelector((state) => state.searchCountReducer);
  const searchScreenReference = useSelector(
    (state) => state.searchScreenReferenceReducer
  );

  const history = useHistory();

  const [isCaseDisabled, setIsCaseDisabled] = useState(true);
  const [isServiceUserDisabled, setIsServiceUserDisabled] = useState(true);
  const [isScreenReferenceDisabled, setIsScreenReferenceDisabled] =
    useState(true);

  const [active, setActive] = useState(searchCountState?.isSearch ? 2 : 0);
  const [userSearch, setuserSearch] = useState("");
  const [caseSearch, setcaseSearch] = useState("");
  const [screenReferenceSearch, setscreenReferenceSearch] = useState("");

  const searchTab = [
    {
      name: "user-details"
    },
    {
      name: "service-userID"
    },
    {
      name: "Case-Number"
    },
    {
      name: "Screen Reference"
    }
  ];

  useEffect(() => {
    if (searchCaseState.flag && searchCaseState?.data?.totalCases > 0) {
      onClose();
      dispatch(resetsearchCase());
      history.push(`/case-list/${caseSearch.toUpperCase()}`);
    }
  }, [searchCaseState]);

  useEffect(() => {
    if (searchUserState.flag && searchUserState?.data?.totalServiceUsers > 0) {
      onClose();
      dispatch(resetsearchUser());
      history.push(`/users/${userSearch.toUpperCase()}`);
    }
  }, [searchUserState]);

  useEffect(() => {
    if (
      searchScreenReference.flag &&
      searchScreenReference?.data?.totalServiceUsers > 0
    ) {
      onClose();
      dispatch(resetsearchScreenReference());
      history.push(
        `/case-list/${searchScreenReference?.data?.caseReference.toUpperCase()}`
      );
    }
  }, [searchScreenReference]);

  const onServiceUserChange = (val) => {
    setuserSearch(val);
    setIsServiceUserDisabled(!val.length || val.length <= 2);

    if (!val.length) {
      dispatch(resetsearchUser());
    }
  };

  const onCaseSearchChange = (val) => {
    setcaseSearch(val);
    setIsCaseDisabled(!val.length || val.length <= 2);

    if (!val.length) {
      dispatch(resetsearchCase());
    }
  };

  const onScreenReferenceChange = (val) => {
    setscreenReferenceSearch(val);
    setIsScreenReferenceDisabled(val.length < 4);

    if (!val.length) {
      dispatch(resetsearchScreenReference());
    }
  };

  const renderForm = (index) => {
    if (index === 0) {
      return (
        <Wrapper className="case-container">
          <UserDetail isDisabled={active !== 0} />
        </Wrapper>
      );
    }
    if (index === 1) {
      return (
        <Wrapper className="case-container">
          <ServiceUserSearch
            onChange={onServiceUserChange}
            isDisabled={active !== 1}
          />
        </Wrapper>
      );
    }
    if (index === 2) {
      return (
        <Wrapper className="case-container">
          <CaseSearch onChange={onCaseSearchChange} isDisabled={active !== 2} />
        </Wrapper>
      );
    }
    if (index === 3) {
      return (
        <Wrapper className="case-container">
          <SearchScreenReference
            onChange={onScreenReferenceChange}
            isDisabled={active !== 3}
          />
        </Wrapper>
      );
    }
    if (index === 5) {
      <Wrapper className="case-container">
        <SearchScreenReference
          onChange={onScreenReferenceChange}
          isDisabled={active !== 4}
        />
      </Wrapper>;
    }
    return null;
  };

  const onSearchSubmit = (type) => {
    dispatch(
      updateSearchCount({
        caseCount: searchCountState.data.caseCount,
        userCount: searchCountState.data.userCount
      })
    );

    if (active === 0 && (type === "case" || type === "user")) {
      onClose();
      dispatch(
        updatesearchFilter({
          [type]: true
        })
      );
      if (type === "user") {
        if (searchCountState?.data?.userCount === 1) {
          dispatch(resetsearchCount());
          dispatch(resetFilters(FILTER_SERVICE_USERS));
          history.push(
            `/users/${searchCountState?.data?.serviceUserReference}`
          );
        } else {
          if (history.location.pathname === "/users") {
            dispatch(resetFilters(FILTER_SERVICE_USERS));
          }
          history.push("/users");
        }
      } else {
        dispatch(noFilters(FILTER_CASE_LIST));
        if (searchCountState?.data?.caseCount === 1) {
          dispatch(resetFilters(FILTER_CASE_LIST));
          dispatch(resetsearchCount());

          history.push(`/case-list/${searchCountState?.data?.caseReference}`);
        } else {
          if (history.location.pathname === "/case-list") {
            dispatch(getCaseList({ filterType: FILTER_CASE_LIST }));
          }
          history.push("/case-list");
        }
      }
    }
    if (active === 1) {
      dispatch(
        getsearchUser({
          ServiceUserReference: userSearch.toUpperCase()
        })
      );
    }
    if (active === 2) {
      dispatch(
        getsearchCase({
          CaseReference: caseSearch.toUpperCase()
        })
      );
    }
    if (active === 3) {
      dispatch(
        getsearchScreenReference({
          CaseReference: screenReferenceSearch.toUpperCase()
        })
      );
    }
  };

  return (
    <>
      <ModalBody isFullScreen>
        <Wrapper className="search-container">
          <Wrapper className="modal-body-container scrollbar" tabIndex={0}>
            {(searchUserState?.error?.isError && !searchUserState?.loading) ||
            (searchCaseState?.error?.isError && !searchCaseState?.loading) ||
            (searchScreenReference?.error?.isError &&
              !searchScreenReference?.loading) ? (
              <Wrapper className="no-search-conatiner">
                <Wrapper className="no-search-icon">
                  <Wrapper className="search-icon">
                    <SearchIcon />
                  </Wrapper>
                  <Wrapper className="cross-icon">
                    <CrossIconFilled />
                  </Wrapper>
                </Wrapper>
                <Typography variant="h2">no-search-result</Typography>
                <Typography variant="label" className="mt-8" align="center">
                  search-criteria
                </Typography>
              </Wrapper>
            ) : (
              <>
                <Typography
                  mb={24}
                  variant="label"
                  display="block"
                  className="search-title">
                  search-title
                </Typography>
                {searchTab.map((item, i) => (
                  <Wrapper
                    key={`search-tab-${i + 1}`}
                    className={`tab-container ${
                      i === 2 ? "user-container" : ""
                    }`}>
                    <Radio
                      value={i + 1}
                      text={item.name}
                      name={item.name}
                      checked={active === i}
                      onChange={(val) => setActive(val - 1)}
                    />
                    {renderForm(i)}
                  </Wrapper>
                ))}
              </>
            )}
          </Wrapper>
        </Wrapper>
      </ModalBody>
      <ModalFooter>
        <SearchFooter
          active={active}
          onClose={onClose}
          isCaseDisabled={isCaseDisabled}
          onSubmitHandler={onSearchSubmit}
          isServiceUserDisabled={isServiceUserDisabled}
          isScreenReferenceDisabled={isScreenReferenceDisabled}
        />
      </ModalFooter>
    </>
  );
};

SearchForm.propTypes = {
  onClose: PropTypes.func.isRequired
};
