import PropTypes from "prop-types";
import React, { useLayoutEffect, useRef } from "react";
import { CheckBox } from "preventx-storybook";
import standardize from "../../../json/standardize";

export const TreeItems = (props) => {
  const { compute, status, parent, count, state } = props;
  const inputRef = useRef(null);

  useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.checked = status === state.checked;
    }
  }, [status, state, count]);

  return (
    <CheckBox
      ref={inputRef}
      id={parent}
      name={parent}
      checked={status === state.checked}
      onChange={() => {
        const newStatus = inputRef.current.checked
          ? state.checked
          : state.unchecked;
        compute(parent, newStatus);
      }}
      label={standardize[parent] || parent}
    />
  );
};

TreeItems.defaultProps = {
  count: 0,
};

TreeItems.propTypes = {
  status: PropTypes.number.isRequired,
  compute: PropTypes.func.isRequired,
  parent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  count: PropTypes.number,
  state: PropTypes.number.isRequired,
};
