import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useCookies } from "react-cookie";
import { useMsal } from "@azure/msal-react";
import { Loader, Wrapper } from "preventx-storybook";
import { loginRequest, logoutRequest } from "../../../AuthConfig";

export const ErrorStatus = () => {
  const [, setCookie] = useCookies(["accessToken"]);
  const history = useHistory();
  const { instance } = useMsal();
  const [isloading, setloading] = useState(false);
  const errorState = useSelector((state) => state.errorStatusReducer);

  useEffect(() => {
    if (errorState && errorState.errorstatus && instance) {
      if (!isloading) {
        setloading(true);
        instance
          .acquireTokenSilent(loginRequest)
          .then((response) => {
            setCookie("accessToken", response.accessToken, { path: "/" });
            history.go(0);
          })
          .catch(async () => {
            const accounts = instance.getAllAccounts();
            const currentAccount = instance.getAccountByHomeId(
              accounts && accounts.length && accounts[0].homeAccountId
            );
            await instance
              .logoutRedirect({
                ...loginRequest,
                account: currentAccount,
                ...logoutRequest
              })
              .catch(() => {
                sessionStorage.clear();
                history.go(0);
              });
          });
      }
    }
  }, [errorState]);

  return (
    isloading && (
      <Wrapper className="error-loading-blk">
        <Loader loading={isloading} />
      </Wrapper>
    )
  );
};
