import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// Components
import {
  Tag,
  Bullet,
  DateRow,
  Wrapper,
  GridItem,
  TableRow,
  parseDate,
  PhoneIcon,
  RowIconTag,
  Typography,
  HashTagIcon,
  MessageIcon,
  LocationIcon,
  GridContainer,
  DateofBirthIcon
} from "preventx-storybook";

const baseClass = "sb-user-list-details";

export const ServiceUserDetails = ({ data }) => {
  const history = useHistory();

  return (
    <Wrapper className="Table caselist__table">
      <Wrapper className="Table__Body caselist__table__body">
        {_.keys(data).map((item) => {
          const serviceUser = data[item];

          return (
            <TableRow
              icon
              dimension
              key={`service-user-list-${serviceUser.ServiceUserReference}`}
              onClick={() =>
                history.push(`/users/${serviceUser.ServiceUserReference}`)
              }>
              <div className={baseClass} data-testid={baseClass}>
                <GridContainer>
                  <GridItem
                    xs={12}
                    md={3}
                    lg={3}
                    xlg={3}
                    className={`${baseClass}__one`}>
                    <div>
                      <Wrapper>
                        <Typography
                          className={`${baseClass}__service-user-name`}>
                          {`${serviceUser?.FirstName} ${serviceUser?.LastName}`}
                        </Typography>
                        <Tag
                          className={`${baseClass}__account-status-mobile`}
                          text={`${serviceUser?.AccountStatus}-Custom`}
                          variant={serviceUser?.AccountStatus}
                        />
                      </Wrapper>

                      <RowIconTag icon={<DateofBirthIcon />} mt={8}>
                        <DateRow inputDate={serviceUser?.DateOfBirth} />
                      </RowIconTag>

                      <RowIconTag icon={<HashTagIcon />}>
                        <Typography>
                          {serviceUser?.ServiceUserReference}
                        </Typography>
                      </RowIconTag>
                    </div>
                  </GridItem>
                  <GridItem
                    xs={12}
                    md={3}
                    lg={3}
                    xlg={3}
                    className={`${baseClass}__two`}>
                    <div>
                      <RowIconTag icon={<PhoneIcon />}>
                        <Typography>
                          {serviceUser?.MobileNumberCountryCode || ""}
                        </Typography>
                        &nbsp;
                        <Typography>{serviceUser?.MobileNumber}</Typography>
                      </RowIconTag>

                      <RowIconTag icon={<MessageIcon />}>
                        <Typography className={`${baseClass}__emailId`}>
                          {serviceUser?.EmailAddress}
                        </Typography>
                      </RowIconTag>

                      <RowIconTag icon={<LocationIcon />}>
                        <Typography>{serviceUser?.Postcode}</Typography>
                      </RowIconTag>
                    </div>
                  </GridItem>
                  <GridItem
                    xs={12}
                    md={3}
                    lg={3}
                    xlg={3}
                    className={`${baseClass}__three`}>
                    <div>
                      {serviceUser?.AccountStatus && (
                        <div className={`${baseClass}__account-status`}>
                          <Tag
                            text={`${serviceUser?.AccountStatus}-Custom`}
                            variant={serviceUser?.AccountStatus}
                          />
                        </div>
                      )}
                      <div className={`${baseClass}__table-container`}>
                        <Typography>LastLogin</Typography>
                        <Bullet
                          className={`${baseClass}__table-container--bullet`}
                        />
                        <Typography
                          className={`${baseClass}__table-container--text--end`}>
                          {serviceUser?.LastLogin
                            ? parseDate(serviceUser.LastLogin, "DD MMM YYYY")
                            : "NotAccessed"}
                        </Typography>
                      </div>
                      <div
                        className={`${baseClass}__table-container ${baseClass}__registered-mobile`}>
                        <Typography>DateCreated</Typography>
                        <Bullet
                          className={`${baseClass}__table-container--bullet`}
                        />
                        <Typography
                          className={`${baseClass}__table-container--text--end`}>
                          {serviceUser?.CreatedDate
                            ? parseDate(serviceUser?.CreatedDate, "DD MMM YYYY")
                            : "Unavailable"}
                        </Typography>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem
                    xs={12}
                    md={3}
                    lg={3}
                    xlg={3}
                    className={`${baseClass}__four`}>
                    <div>
                      <div className={`${baseClass}__table-container`}>
                        <Typography
                          className={`${baseClass}__table-container--text`}>
                          CasesOpen
                        </Typography>
                        <Bullet
                          className={`${baseClass}__table-container--bullet`}
                        />
                        <Typography
                          className={`${baseClass}__table-container--text--end`}>
                          {serviceUser?.openCount || "0"}
                        </Typography>
                      </div>
                      <div className={`${baseClass}__table-container`}>
                        <Typography
                          className={`${baseClass}__table-container--text`}>
                          CaseStatusClosed
                        </Typography>
                        <Bullet
                          className={`${baseClass}__table-container--bullet`}
                        />
                        <Typography
                          className={`${baseClass}__table-container--text--end`}>
                          {serviceUser?.closedCount || "0"}
                        </Typography>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
              </div>
            </TableRow>
          );
        })}
      </Wrapper>
    </Wrapper>
  );
};

ServiceUserDetails.defaultProps = {
  data: []
};

ServiceUserDetails.propTypes = {
  data: PropTypes.instanceOf(Object)
};
