import React, { useEffect, useState } from "react";
import { Loader, Wrapper } from "preventx-storybook";
import { useMsal } from "@azure/msal-react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { loginRequest } from "../../../AuthConfig";
import * as PATH from "../../../constants/paths";
import { apiException } from "../../../services/api";

export const LoginRedirect = () => {
  const dispatch = useDispatch();
  const [, setCookie] = useCookies(["accessToken"]);
  const { instance } = useMsal();
  const [isloading, setloading] = useState(true);

  useEffect(() => {
    if (instance) {
      let accounts = instance.getAllAccounts();
      if (isloading && !accounts.length) {
        setloading(true);
        (async () => {
          try {
            const result = await instance.handleRedirectPromise();
            accounts = instance.getAllAccounts();
            if (accounts.length === 0) {
              instance
                .loginRedirect(loginRequest)
                .catch(() => dispatch(apiException(null, "Login-Failed")));
            } else if (result?.accessToken) {
              setCookie("accessToken", result.accessToken, { path: PATH.ROOT });
              setloading(false);
              window.location.replace(PATH.TODAY_LIST);
            }
          } catch {
            instance
              .loginRedirect(loginRequest)
              .catch(() => dispatch(apiException(null, "Login-Failed")));
          }
        })();
      } else {
        window.location.replace(PATH.TODAY_LIST);
      }
    }
  }, []);

  return (
    <Wrapper flex align="center" fw style={{ "justify-content": "center" }}>
      <Loader size={48} height="100vh" loading={isloading} />
    </Wrapper>
  );
};
