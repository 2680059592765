import _ from "lodash";
import {
  BackDrop,
  Drawer,
  Filters as Filter,
  Loader
} from "preventx-storybook";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleBackdrop } from "../../../store/backdrop/actionCreator";
import {
  getfilterList,
  resetFilters,
  setCurrentFilters,
  setFilterList,
  setToggleFilterDrawer,
  toggleFilterCollapse
} from "../../../store/filterlist/actionCreator";
import { filterBodyHeight } from "../../../utils/utilsFunction";
import { useWindow } from "../../hooks/useWindow";

export const Filters = ({ filterType, closeLabel }) => {
  const [height, setHeight] = useState();
  const dispatch = useDispatch();
  const filterReducer = useSelector((state) => state.filterListReducer);
  const { showBackdrop } = useSelector((state) => state.backDropReducer);

  const { loading, isFilterOpen } = filterReducer;
  const {
    error,
    filters,
    currentFilters,
    collapsedList,
    defaultChecked,
    defaultCollapsed,
    filterCounts,
    filterCountLoading
  } = filterReducer[filterType];

  /** retrieve filter list */
  useEffect(() => {
    if (filters?.length < 1) {
      dispatch(getfilterList(filterType));
    }
  }, [filterType, dispatch, filters?.length]);

  /** Unchecks all the filters */
  const resetAllFilters = () => {
    dispatch(resetFilters(filterType));
    dispatch(setToggleFilterDrawer(false));
    dispatch(toggleBackdrop(false));
  };

  const toggleCollapse = (updatedList) => {
    if (_.keys(updatedList).length > 0) {
      dispatch(toggleFilterCollapse(updatedList, filterType));
    }
  };

  const applyFilters = ({ params, itemsState }) => {
    if (filters.length > 0) {
      dispatch(setFilterList(itemsState, filterType));
      dispatch(
        setCurrentFilters({
          filters: [...params],
          filterType
        })
      );
    }
  };

  const filterHandler = () => {
    dispatch(setToggleFilterDrawer());
    dispatch(toggleBackdrop(false));
  };

  const calculateHeight = () => {
    const dynamicHeight = filterBodyHeight();
    setHeight(dynamicHeight);
  };

  useWindow(calculateHeight, []);

  useEffect(() => {
    calculateHeight();
  }, []);

  return (
    <>
      <Drawer borderRight className=" filters" isOpen={isFilterOpen}>
        <Loader
          loading={loading}
          error={error.isError}
          errorMessage={error.message}
          noData={filters.length < 1}
          height="calc(100vh - 50px)">
          <Filter
            filterBodyStyle={{ height, overflowY: "auto" }}
            scrollable
            list={filters}
            selected={currentFilters}
            onChangeHandler={applyFilters}
            resetHandler={resetAllFilters}
            closeHandler={filterHandler}
            closeLabel={closeLabel}
            collapseHandler={toggleCollapse}
            defaultCollapsed={defaultCollapsed}
            defaultChecked={defaultChecked}
            collapsed={collapsedList}
            filterCounts={filterCounts}
            filterCountLoading={filterCountLoading}
          />
        </Loader>
      </Drawer>
      <BackDrop toggleBackDrop={showBackdrop} />
    </>
  );
};

Filters.defaultProps = {
  closeLabel: "Back-To-Cases"
};

Filters.propTypes = {
  filterType: PropTypes.string.isRequired,
  closeLabel: PropTypes.string
};
