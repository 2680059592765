import {
  all,
  put,
  takeEvery,
  takeLatest,
  delay
} from "@redux-saga/core/effects";
import _ from "lodash";
import { stringify } from "query-string";
import {
  getDefaultCheckedFilters,
  getNestedFilters,
  resetTree,
  state,
  traverse
} from "../../components/molecules/Tree/treeHelper";
import {
  FILTER_CASE_LIST,
  FILTER_SERVICE_USERS,
  FILTER_WORK_LIST
} from "../../constants/ids";
import { apiCall, apiException } from "../../services/api";
import {
  getState,
  injectServiceProviderRef,
  pageStateDetails
} from "../../utils/helper";
import { getCaseList } from "../caselist/logic";
import { getTodayList } from "../todaylist/logic";
import { getServiceUserReferences } from "../userdetails/actionCreators";
import {
  clearFiltersForSearch,
  setDefaultCollapsed,
  setDefaultFilters,
  setFilterCollapse,
  setFilterCounts,
  setFilterList,
  setFiltersSelected,
  updateFilterPayload
} from "./actionCreator";
import {
  FILTER_LIST,
  FILTER_LIST_ERROR,
  GET_FILTER_COUNTS,
  NO_FILTERS,
  RESET_FILTERS,
  SET_CURRENT_FILTERS,
  SET_FILTER_COUNTS_ERROR,
  TOGGLE_FILTER_COLLAPSE
} from "./actionType";
import { getUpdatedFiltersList } from "../../utils/settings";

function* filterListGet({ filterType }) {
  const url = getUrl(filterType);

  try {
    const response = yield apiCall({
      method: "get",
      url
    });
    const filterListResponse = yield injectServiceProviderRef(response);
    const updatedResponse = yield getUpdatedFiltersList(filterListResponse);

    if (filterType === FILTER_CASE_LIST) {
      const { defaultChecked, defaultCollapsed } = yield pageStateDetails(
        FILTER_CASE_LIST
      );

      const initialTreeState = _.cloneDeep(updatedResponse);

      if (defaultChecked.length > 0 && defaultCollapsed.length > 0) {
        const treeState = traverse(
          [...initialTreeState],
          "Open",
          state.checked
        );
        const defaultFilters = getDefaultCheckedFilters(treeState);
        const params = getNestedFilters(defaultFilters);

        yield all([
          put(setFilterList(treeState, filterType)),
          put(
            setFiltersSelected({
              filters: params,
              filterType
            })
          )
        ]);
      } else {
        yield put(setFilterList(updatedResponse, filterType));
      }
    }
    // to be removed later.
    else {
      yield put(setFilterList(updatedResponse, filterType));
    }
  } catch (e) {
    yield put(
      apiException({
        type: FILTER_LIST_ERROR,
        filterType,
        message: e.message || "Filter-Fail",
        code: e.code || e
      })
    );
  }
}

function* filterCountsGet({ filters, filterType }) {
  try {
    const response = yield apiCall({
      method: "get",
      url: `worklists/filtercounts?${stringify({
        Filters: filters
      })}`
    });

    if (filterType !== FILTER_SERVICE_USERS) {
      const serviceProviderList = yield getState(
        "serviceProvidersReducer",
        "serviceProvidersList"
      );

      if (serviceProviderList?.length > 0) {
        _.forEach(serviceProviderList, (sp) => {
          if (response[sp.serviceProviderReference]) {
            response[sp.name] = response[sp.serviceProviderReference];
            delete response[sp.serviceProviderReference];
          }
        });
      }
    }

    yield put(setFilterCounts(filterType, response));
  } catch (e) {
    yield put(
      apiException({
        type: SET_FILTER_COUNTS_ERROR,
        filterType,
        message: e.message || "Filter-Count-Fail",
        code: e.code || e
      })
    );
  }
}

function getUrl(filterType) {
  switch (filterType) {
    case "workList":
      return "Worklists/Filters";
    case "caseList":
      return "Cases/Filters";
    case "usersList":
      return "serviceusers/filters";
    default:
      return "#";
  }
}

function* resetCaseListPage() {
  const { filters } = yield pageStateDetails(FILTER_CASE_LIST);

  const defaultCollapsed = ["CaseStatus", "Open"];

  let initialTreeState = _.cloneDeep(filters);
  initialTreeState = resetTree(initialTreeState);

  const treeState = traverse([...initialTreeState], "Open", state.checked);
  const defaultFilters = getDefaultCheckedFilters(treeState);
  const params = getNestedFilters(defaultFilters);

  yield all([
    put(setFiltersSelected({ filters: params, filterType: FILTER_CASE_LIST })),
    put(setDefaultCollapsed(defaultCollapsed, FILTER_CASE_LIST)),
    put(setDefaultFilters(params, FILTER_CASE_LIST)),
    put(setFilterList(treeState, FILTER_CASE_LIST)),
    put(setFilterCollapse({ CaseStatus: true, Open: true }, FILTER_CASE_LIST))
  ]);
}

function* resetFilters({ filterType, isReloadPage }) {
  yield put(setFilterCounts(filterType));
  const { filters } = yield pageStateDetails(filterType);
  if (filters.length < 1) {
    return;
  }
  if (filterType === FILTER_CASE_LIST && filters?.length > 0) {
    yield resetCaseListPage();
    if (isReloadPage) {
      yield put(getCaseList({ filterType: FILTER_CASE_LIST }));
    }
    return;
  }

  let initialTreeState = _.cloneDeep(filters);
  initialTreeState = resetTree(initialTreeState);

  yield all([put(clearFiltersForSearch(initialTreeState, filterType))]);
  if (isReloadPage) {
    if (filterType === FILTER_WORK_LIST) {
      yield all([put(getTodayList({ filterType: FILTER_WORK_LIST }))]);
      return;
    }
    if (filterType === FILTER_SERVICE_USERS) {
      yield all([
        put(getServiceUserReferences({ filterType: FILTER_SERVICE_USERS }))
      ]);
    }
  }
}

function* noFilters({ filterType }) {
  // funciton to clear all checked filters
  const { filters } = yield pageStateDetails(filterType);
  if (filters.length < 1) {
    return;
  }

  if (filterType === FILTER_CASE_LIST) {
    let initialTreeState = _.cloneDeep(filters);
    initialTreeState = resetTree(initialTreeState);
    yield all([
      put(clearFiltersForSearch(initialTreeState, filterType)),
      put(updateFilterPayload([], filterType))
    ]);
    return;
  }
  yield resetFilters({ filterType });
}

function* filterCollapse({ collapsedList, filterType }) {
  yield put(setFilterCollapse(collapsedList, filterType));
}

function* filtersSelected({ data }) {
  yield put(setFiltersSelected(data));
  yield delay(1000);

  if (data.filterType === FILTER_CASE_LIST) {
    yield put(getCaseList(data));
  } else if (data.filterType === FILTER_SERVICE_USERS) {
    yield put(getServiceUserReferences(data));
  } else {
    yield put(getTodayList(data));
  }
}

export function* filterListGETAction() {
  yield takeLatest([FILTER_LIST], filterListGet);
  yield takeLatest([GET_FILTER_COUNTS], filterCountsGet);
  yield takeEvery(RESET_FILTERS, resetFilters);
  yield takeEvery(NO_FILTERS, noFilters);
  yield takeLatest(TOGGLE_FILTER_COLLAPSE, filterCollapse);
  yield takeLatest(SET_CURRENT_FILTERS, filtersSelected);
}
