import React, { useState } from "react";
import { TextField } from "preventx-storybook";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const SearchScreenReference = ({ onChange, isDisabled }) => {
  const searchScreenReference = useSelector(
    (state) => state.searchScreenReferenceReducer
  );
  const [search, setSearch] = useState(
    searchScreenReference?.data?.caseReference || ""
  );
  return (
    <TextField
      placeholder="PX123456 / SR123456"
      value={search}
      handler={(e) => {
        setSearch(e.target.value);
        onChange(e.target.value);
      }}
      isDisabled={isDisabled}
      className="txt-cap"
    />
  );
};

SearchScreenReference.defaultProps = {
  onChange: () => null,
  isDisabled: false
};

SearchScreenReference.propTypes = {
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool
};

export default SearchScreenReference;
